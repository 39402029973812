// ArchiveFilter
//
// Version: v 0.1.1
// Written: Masaaki Sumimoto
//
// - アーカイブ一覧ページにおける絞り込みフォームUIに対応したスクリプト。

import ContentCloser from '../ArchiveFilter/_ContentCloser';
import ContentWrap from '../ArchiveFilter/_ContentWrap';
import Content from '../ArchiveFilter/_Content';
import Selector from '../ArchiveFilter/_Selector';
import CheckboxGroup from '../ArchiveFilter/_CheckboxGroup';
import AreaPartialMapImg from '../ArchiveFilter/_AreaPartialMapImg';
import AreaImagemapArea from '../ArchiveFilter/_AreaImagemapArea';
import AreaListBtn from '../ArchiveFilter/_AreaListBtn';
import AreaCheckboxGroup from '../ArchiveFilter/_AreaCheckboxGroup';
import ImagemapResizer from '../ArchiveFilter/_ImagemapResizer';

class ArchiveFilter {
  constructor(opts = {}) {
    // 必要な情報の準備
    //

    this.config = {
      disableAreaMapMode: opts.disableAreaMapMode === true ? true : false,
      parentCkSelectorName: '[data-role="archive-filter-parent-ck"]',
      areaParentCkSelectorName: '[data-role="archive-filter-area-parent-ck"]',
    };

    this.state = {
      activeContentId: null,
      hideClassName: null,
      isContentActive: false,
      activeAreaIds: [],
    };

    this.appSelector = opts.appSelector ?? document.querySelector('[data-role="archive-filter"]');
    console.log('KanagawaArchiveFilter', this.appSelector);
    this.dom = {
      app: this.appSelector,
      selectors: this.appSelector.querySelectorAll('[data-role="archive-filter-selector"]'),
      closer: this.appSelector.querySelector('[data-role="archive-filter-content-closer"]'),
      contentWrap: this.appSelector.querySelector('[data-role="archive-filter-content-wrap"]'),
      content: this.appSelector.querySelectorAll('[data-role="kanagawa-archive-filter-content"]'),
      parentCheckBox: this.appSelector.querySelectorAll(this.config.parentCkSelectorName),
      areaPartialMapImgs: document.querySelectorAll('[data-role="kanagawa-archive-filter-area-partial-map-img"]'),
      areaImagemapAreas: document.querySelectorAll('[data-role="kanagawa-archive-filter-area-imagemap-area"]'),
      areaListBtns: document.querySelectorAll('[data-role="kanagawa-archive-filter-area-btn"]'),
      areaCheckboxGroups: document.querySelectorAll(this.config.areaParentCkSelectorName),
      areaMap: this.appSelector.querySelector('[data-role="kanagawa-archive-filter-area-map"]'),
      areaMapBaseImg: this.appSelector.querySelector('[data-role="kanagawa-archive-filter-area-base-map-img"]'),
    };

    // DOMの存在がない場合のエラー処理
    //

    if (
      !this.dom.app ||
      !this.dom.selectors ||
      !this.dom.closer ||
      !this.dom.contentWrap ||
      !this.dom.content ||
      !this.dom.parentCheckBox
    ) {
      throw new Error(
        '[Kanagawa Archive Filter] なんらかの必須domが存在しません。正しくhtmlタグにdata属性を割り当ててください'
      );
      // eslint-disable-next-line no-unreachable
      return;
    }

    if (this.config.disableAreaMapMode === false) {
      if (!this.dom.areaMap || !this.dom.areaMapBaseImg) {
        throw new Error(
          '[Kanagawa Archive Filter] エリアマップに関わる必須domが存在しません。正しくhtmlタグにdata属性を割り当ててください'
        );
        // eslint-disable-next-line no-unreachable
        return;
      }
    }

    // Basic Elements
    //
    // html要素に紐つけたしたクラスインスタンス

    this.elm = this.dom.app;

    this.selectors = [];
    this.dom.selectors.forEach((selector) => {
      this.selectors.push(new Selector({ elm: selector, app: this }));
    });

    this.contentCloser = new ContentCloser({
      elm: this.dom.closer,
      app: this,
    });

    this.contentWrap = new ContentWrap({
      elm: this.dom.contentWrap,
      app: this,
    });

    this.contents = [];
    this.dom.content.forEach((content) => {
      this.contents.push(new Content({ elm: content, app: this }));
    });

    this.checkboxGroups = [];
    this.dom.parentCheckBox.forEach((parentCk) => {
      this.checkboxGroups.push(
        new CheckboxGroup({
          parentElm: parentCk,
          app: this,
        })
      );
    });

    // Area Filter Elements
    //
    // html要素に紐つけたしたクラスインスタンス
    //
    // diableAreaMapMode が無効 ( = エリアマップを利用する)場合のみ
    // インスタンスを生成

    if (this.config.disableAreaMapMode === false) {
      this.areaPartialMapImgs = [];
      this.dom.areaPartialMapImgs.forEach((slice) => {
        this.areaPartialMapImgs.push(
          new AreaPartialMapImg({
            elm: slice,
            app: this,
          })
        );
      });

      this.areaImagemapAreas = [];
      this.dom.areaImagemapAreas.forEach((btn) => {
        this.areaImagemapAreas.push(
          new AreaImagemapArea({
            elm: btn,
            app: this,
          })
        );
      });

      this.areaListBtns = [];
      this.dom.areaListBtns.forEach((btn) => {
        this.areaListBtns.push(
          new AreaListBtn({
            elm: btn,
            app: this,
          })
        );
      });

      this.areaCheckboxGroups = [];
      this.dom.areaCheckboxGroups.forEach((parentCk) => {
        this.areaCheckboxGroups.push(
          new AreaCheckboxGroup({
            parentElm: parentCk,
            parentSelectorName: this.config.areaParentCkSelectorName,
            app: this,
          })
        );
      });

      this.imagemapResizer = new ImagemapResizer({
        app: this,
        mapElm: this.dom.areaMap,
        baseImgElm: this.dom.areaMapBaseImg,
      });
    }

    // event
    //

    window.addEventListener('resize', () => {
      if (this.imagemapResizer) this.imagemapResizer.render();
    });

    // init
    //

    this.init();
  }

  init() {
    this.state.hideClassName = this.elm.dataset.hideClassName;

    // ロード時の画面のチェックボックスの状態をstateに反映
    if (this.config.disableAreaMapMode === false) {
      this.areaCheckboxGroups.forEach((ckGroup) => {
        if (ckGroup.parentElm.checked === true) {
          this.state.activeAreaIds.push(ckGroup.groupId);
        }
      });
    }

    this.render();
  }

  render() {
    this.contentWrap.render();

    if (this.selectors) {
      this.selectors.forEach((selector) => {
        selector.render();
      });
    }

    this.contents.forEach((content) => {
      content.render();
    });

    if (this.config.disableAreaMapMode === false) {
      this.areaImagemapAreas.forEach((area) => {
        area.render();
      });

      this.areaPartialMapImgs.forEach((img) => {
        img.render();
      });

      this.areaListBtns.forEach((btn) => {
        btn.render();
      });

      this.areaCheckboxGroups.forEach((ckGroup) => {
        ckGroup.render();
      });

      // getBoundingClientRectが正しく要素の高さを取得するためやや遅延させる
      setTimeout(() => {
        this.imagemapResizer.render();
      }, 300);
    }
  }

  // global methods
  //

  close() {
    this.state.isContentActive = false;
    this.state.activeContentId = null;
    this.render();
  }
}

export default ArchiveFilter;
