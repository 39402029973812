// bookingページ: たびくらフォームのサブミットの定義
if (document.querySelector('[data-role="booking-form"]') !== null) {
  document.querySelectorAll('[data-role="booking-filter-btn"]').forEach((elm) => {
    const input = elm.querySelector('input');
    input.addEventListener('click', () => {
      if (elm.classList.contains('active')) {
        elm.classList.remove('active');
      } else {
        elm.classList.add('active');
      }
    });
  });

  document.querySelector('[data-role="booking-submit"]').addEventListener('click', () => {
    // Formより参照した値を利用
    const url = document.bookingForm.action;
    const prefVal = document.bookingForm.pf.value;
    const numberOfPeopleVal = document.bookingForm.cp.value;
    const numberOfRoomVal = document.bookingForm.rn.value;
    const minBudgetVal = document.bookingForm.pn.value;
    const maxBudgetVal = document.bookingForm.px.value;
    const roomTypeVal = document.bookingForm.rt.value;
    const mealTypeVal = document.bookingForm.mc.value;
    const keywordsVal = document.bookingForm.kw.value;

    // Formから参照した値を加工し利用
    const dateVal = document.bookingForm.sd.value.replace(/-/g, '');
    const kodawariNodes = document.bookingForm.kd;
    const kodawariVals = [];
    Array.prototype.slice.call(kodawariNodes, 0).forEach((node) => {
      if (node.checked) kodawariVals.push(node.value);
    });

    const requestURL = `${url}?pf=${prefVal}&sd=${dateVal}&cp=${numberOfPeopleVal}&rn=${numberOfRoomVal}&pn=${minBudgetVal}&px=${maxBudgetVal}&rt=${roomTypeVal}&mc=${mealTypeVal}&kw=${keywordsVal}&kd=${kodawariVals}`;

    // debug
    // console.log(requestURL);

    window.location.href = requestURL;
    return false;
  });
}
